// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as EmbedTypes$Showside from "./types/EmbedTypes.bs.js";
import * as CriticalCSS$Showside from "./utils/CriticalCSS.bs.js";
import * as Webapi__Dom__Element from "../../../node_modules/rescript-webapi/lib/es6_global/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as Configuration$Showside from "./types/Configuration.bs.js";
import * as ConfigIdGetter$Showside from "./ConfigIdGetter.bs.js";
async function initSmartnav(index, element) {
  EmbedTypes$Showside.Attributes.autoAssignNamespaces(undefined);
  var attributes = EmbedTypes$Showside.Attributes.fromElement(element);
  var configId = await ConfigIdGetter$Showside.getConfigId(undefined, undefined, undefined, attributes, /* Inline */1);
  if (configId !== undefined) {
    return Configuration$Showside.preloadConfig(true, undefined, configId);
  }
}
function getElements(param) {
  return Belt_Array.keepMap(Belt_Array.map(Array.prototype.slice.call(document.querySelectorAll("#maker-smartnav-inline")), Webapi__Dom__Element.ofNode), function (node) {
    return node;
  });
}
function init(param) {
  var elements = getElements(undefined);
  if (elements.length !== 0) {
    Belt_Array.mapWithIndex(elements, initSmartnav);
    CriticalCSS$Showside.insertCSS(undefined, undefined);
    import('./types/ConfigurationDecoder.bs');
    import('./InlineEntrypoint.bs').then(entrypoint => {
      entrypoint.init();
      window.SmartNavInline = entrypoint;
      if (!window.SmartNav) {
        window.SmartNav = window.SmartNavInline;
      }
    });
    return import('./utils/Slider.bs');
  } else {
    console.warn("Could not initialize SmartNav: no element found");
    return;
  }
}
export { initSmartnav, getElements, init };
/* EmbedTypes-Showside Not a pure module */